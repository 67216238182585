import {
  Button,
  EButtonTheme,
  EButtonVariant,
  EInputRounded,
  EInputVariant,
  IconCheck,
  IconAttention,
  Input,
  RichText,
} from '@front/shared/ds';
import clsx from 'clsx';
import React, { useEffect, useState, useRef } from 'react';

import { ENewsletterFormLayout } from './constants';
import { useActiveCampaignForm } from '../../../features/activeCampaign/useActiveCampaignForm';

export type TNewsletterFormProps = {
  formId: string;
  layout?: ENewsletterFormLayout; // for desktop
  buttonVariant?: EButtonVariant;
  buttonTheme?: EButtonTheme;
  inputRounded?: EInputRounded;
  inputVariant?: EInputVariant;
  buttonRounded?: boolean;
  submitButtonText?: string;
  additionalText?: {
    [k: string]: unknown;
  }[];
  emailRequiredText?: string;
  emailInvalidText?: string;
  resubscribeText?: string;
  confirmationMessage?: {
    [k: string]: unknown;
  }[];
  buttonClassName?: string;
  inputClassName?: string;
};

const field = {
  id: 'email',
  name: 'email',
  required: true,
  placeholder: 'Email',
  validationTextError: 'Fill correct email',
};

export const NewsletterForm: React.FC<TNewsletterFormProps> = ({
  formId,
  layout = ENewsletterFormLayout.Row,
  buttonTheme = EButtonTheme.Control,
  buttonVariant = EButtonVariant.Outlined,
  inputVariant = EInputVariant.Primary,
  inputRounded,
  buttonRounded,
  additionalText,
  submitButtonText,
  confirmationMessage,
  emailRequiredText,
  emailInvalidText,
  resubscribeText,
  buttonClassName,
  inputClassName,
}) => {
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const confirmationTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(
    null,
  );

  const onSubmit = (): void => {
    setShowConfirmationMessage(true);

    if (confirmationTimeoutRef.current) {
      clearTimeout(confirmationTimeoutRef.current);
    }

    confirmationTimeoutRef.current = setTimeout(() => {
      setShowConfirmationMessage(false);
    }, 5000);
  };

  const { formState, register, handleSubmit } = useActiveCampaignForm({
    formId,
    onSubmit,
  });

  useEffect(() => {
    return () => {
      if (confirmationTimeoutRef.current) {
        clearTimeout(confirmationTimeoutRef.current);
      }
    };
  }, []);

  const isResubscribe = formState.submitCount > 1 && resubscribeText;
  const IconMessage = isResubscribe ? IconAttention : IconCheck;

  return (
    <>
      <form
        className={clsx('flex flex-col items-start gap-3', {
          'md:flex-row': layout === ENewsletterFormLayout.Row,
        })}
        onSubmit={handleSubmit}
      >
        {/*
         * Partially extracted from front/master/src/components/sections/FormInputParser
         * Replace to `FormInputParser` for rendering different input types
         */}
        <div className='w-full'>
          <Input
            {...register(field.name, {
              required: emailRequiredText || 'Fill email',
              pattern: {
                value:
                  /^[a-zA-Z0-9._%+ "'/!(),:;<>@\\[\]-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                message: emailInvalidText || field.validationTextError,
              },
            })}
            className='h-14'
            inputClassName={inputClassName}
            type='text'
            autoComplete='email'
            showRequiredSymbol={false}
            placeholder={field.placeholder}
            variant={inputVariant}
            rounded={inputRounded}
            error={formState.errors[field.name]?.message?.toString()}
            fullWidth
          />
        </div>

        <Button
          type='submit'
          theme={buttonTheme}
          variant={buttonVariant}
          className={clsx(
            'w-full min-w-[8.438rem] leading-relaxed',
            {
              'rounded-full': buttonRounded,
              'rounded-2xl': !buttonRounded,
              'md:w-auto':
                layout === ENewsletterFormLayout.Row &&
                (submitButtonText?.length || 0) < 10,
              'h-14': layout === ENewsletterFormLayout.Row,
              'h-15': layout === ENewsletterFormLayout.Column,
            },
            buttonClassName,
          )}
        >
          <span className='w-full truncate'>
            {submitButtonText || 'Subscribe'}
          </span>
        </Button>
      </form>

      {additionalText && (
        <div className='prose-a:underline mt-3 text-xs font-normal leading-loose text-interface-500'>
          <RichText content={additionalText} />
        </div>
      )}

      {showConfirmationMessage && (
        <div className='fixed inset-x-0 bottom-10 z-10 mx-auto flex max-w-md animate-translate-up items-center gap-4 rounded-2xl bg-surface-50/30 px-6 py-5 text-sm leading-loose backdrop-blur-md'>
          <IconMessage className='aspect-square h-5 w-5 text-success' />
          {isResubscribe ? (
            resubscribeText
          ) : (
            <RichText content={confirmationMessage} />
          )}
        </div>
      )}
    </>
  );
};
